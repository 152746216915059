<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-text class="text-center">
          <v-avatar size="60" class="mb-4">
            <img src="@/assets/images/logo.svg" alt="" />
          </v-avatar>

          <h6 class="text--disabled font-weight-medium mb-10">
            Inicio de sesión
          </h6>
          <form @submit.prevent="formSubmit">
            <div style="display: flex; justify-content: flex-start">
              <small style="text-align: start !important">Usuario:</small>
            </div>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              required
              style="padding-top: 0"
              id="user_input"
            />

            <div style="display: flex; justify-content: flex-start">
              <small style="text-align: start !important">Contraseña:</small>
            </div>
            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              :counter="20"
              :rules="nameRules"
              v-model="ePassword"
              @click:append="show = !show"
              style="padding-top: 0"
              id="password_input"
            ></v-text-field>
            <!-- <v-checkbox
              v-model="checkbox1"
              label="Remember this computer"
            ></v-checkbox> -->
            <v-btn
              class="mb-4"
              :loading="loading"
              block
              color="primary"
              type="submit"
              dark
            >
              <v-icon left>mdi-login</v-icon>
              ENTRAR</v-btn
            >
            <!-- <v-btn
              class="mb-4"
              @click="googleSignIn"
              block
              color="#DB4437"
              dark
            >
              <v-icon left> mdi-google </v-icon>

              Sign In
            </v-btn>
            <div class="d-flex justify-around flex-wrap">
              <v-btn text small color="primary" class="mb-2"
                >Forgot Password</v-btn
              >
              <v-btn text small color="primary" to="/app/sessions/sign-up-2"
                >Create New Account</v-btn
              >
            </div> -->
            <v-alert
              :value="alert"
              :color="alertColor"
              v-if="alertColor !== 'green'"
              dark
              border="top"
              transition="scale-transition"
            >
              {{ message }}
            </v-alert>
            <div class="d-flex justify-content-center pb-3" v-if="captchaEnable">
              <div id="html_element" style="text-align:center"></div>
            </div>
          </form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
// import firebase from "firebase/app";
import { mapState, mapActions } from "vuex";
export default {
  name: "LoginSilver",

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "SignIn Two",
  },
  created() {
    if (this.loggedInUserSilver) this.$router.push("/");
  },

  mounted() {
    const $user_input = this.$el.querySelector("#user_input");
    const $password_input = this.$el.querySelector("#password_input");
    $password_input?.focus();
    $user_input?.focus();
  },

  data: () => ({
    show: false,
    password: "Password",
    checkbox1: true,
    checkbox2: false,
    email: "",
    ePassword: "",
    loading: false,
    emailRules: [
      (v) => !!v || "Ingresa un correo",
      (v) => /.+@.+\..+/.test(v) || "Proporciona un correo válido",
    ],
    nameRules: [
      (v) => !!v || "Se requiere una contraseña",
      (v) =>
        (v && v.length >= 4) ||
        "La contraseña debe tener al menos 4 carácteres",
    ],
    message: "",
    alert: false,
    alertColor: "red",

    // captcha
    captchaEnable: false,
    captchaToken: null
  }),

  watch: {
    alert(val) {
      if (val && this.alertColor === "red")
        setTimeout(() => {
          this.alert = false;
        }, 3000);
    },
    loggedInUserSilver(val) {
      if (val && val.uid) {
        this.loading = true;
        setTimeout(() => {
          this.$router.push("/home/");
        }, 2000);
      }
    },
  },

  computed: {
    ...mapState({
      loggedInUserSilver: (state) => state.loginSilver.loggedInUserSilver,
    }),
  },

  methods: {
    ...mapActions(["loginAction"]),

    /**
     * Send login request
     */
    async formSubmit() {
      this.loading = true;
      this.alert = false;
      let login
      localStorage.setItem("user_email", this.email);
      if(this.captchaEnable){
        login = await this.loginAction({
          email: this.email,
          password: this.ePassword,
          captchaToken: this.captchaToken
        });
      }else {
        login = await this.loginAction({
          email: this.email,
          password: this.ePassword,
        })
      }
      this.captchaEnable = false;
      
      if (login.response) {
        this.message = "Inicio de sesión correcto";
        this.alertColor = "green";
      } else {
        this.loading = false;
        this.alertColor = "red";
        localStorage.removeItem("userInfo");

        // require captcha?
        let errors = login.message;
        if(errors){

          // enable captcha
          this.message = errors.message;

          if(errors.apiMessage == 'MAX_LOGIN_ATTEMPTS'){
            
            this.captchaEnable = true;
            /* eslint-disable */
            try {
              setTimeout(() => {
                grecaptcha.render('html_element', {
                  'sitekey' : '6Ldt0gAVAAAAAGVHDBF3kvUy2I2IkltzgjNENxdY',
                  'callback' : this.verifyCallback,
                });
              }, 1000);

            } catch (error) {
              
            }
            /* eslint-enab|le */
          }

        } else {
          
          this.message = "Inicio de sesión inválido. Verifique sus datos."; // login.message;
          
        }
      }
      this.alert = true;
    },

    verifyCallback(response){
      this.captchaToken = response;
    }
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.d-flex {
    display: flex !important;
}

.justify-content-center {
    justify-content: center !important;
}

.pb-3, .py-3 {
    padding-bottom: 1rem !important;
}
</style>
